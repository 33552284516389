<template>
  <div class="home-page">
    <div class="top-banner">
      <!--首页轮播-->
      <div class="Choices_banner">
        <ul ref="bannerList">
          <li v-for="(item, index) in bannerItems" :key="index">
            <img
              :src="BASE_URL + item"
              :alt="item.alt"
              style="width: 100%; height: 100vh"
            />
          </li>
        </ul>
      </div>
      <div class="choice-part" ref="numList">
        <div class="banner-num">
          <div class="no-num">01</div>
          <div class="Choices_line" style="width: 100%"></div>
          <div style="height: 2px; background: #ffffff"></div>
        </div>
        <div class="banner-num">
          <div class="no-num">02</div>
          <div class="Choices_line"></div>
          <div style="height: 2px; background: #ffffff"></div>
        </div>
        <div class="banner-num">
          <div class="no-num">03</div>
          <div class="Choices_line"></div>
          <div style="height: 2px; background: #ffffff"></div>
        </div>
      </div>
    </div>
    <!-- 公司业务 -->
    <div class="modul_dec ">
      <!-- 公司业务float在左边 -->
      <div>
        <span class="module-title">公司业务</span>
        <span class="learnmore">Learn more ></span>
      </div>
      <div>
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div>
        <span class="module-title down">Business Areas</span>
      </div>
    </div>
    <!-- 公司介绍 -->
    <div class="modul_dec description">
      <!-- 公司业务float在左边 -->
      <div class="com-desc">
        <span class="module-title">公司介绍</span>
        <span class="learnmore">Learn more ></span>
      </div>
      <div class="com-desc">
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div class="com-desc">
        <span class="module-title down">Company Profile</span>
      </div>
      <div class="com-desc com-content">
        <div class="com-content-left contentdiv">
          <div class="companyimg">
            <img src="@/assets/companys/test.png" alt="" />
          </div>
        </div>
        <div class="com-content-right contentdiv">
          <div class="companytitle">
              HOMESCAPE PROPERTIES
          </div>
          <div class="companydec">
我们是一家专注于地产开发和投资的公司，致力于为客户提供高质量的房地产解决方案。无论您是寻找理想的住宅、商业物业还是投资机会，我们都能为您提供全方位的专业服务。作为一家经验丰富的地产公司，我们在市场上拥有广泛的资源和合作伙伴网络。我们的团队由行业专业人士组成，具备丰富的知识和技能，能够为客户提供全面的咨询和支持。我们致力于打造具有创意和高品质的地产项目，注重细节和设计。
          </div>
        </div>
      </div>
    </div>
    <div class="modul_dec">
      <!-- 公司业务float在左边 -->
      <div>
        <span class="module-title">公司业务</span>
        <span class="learnmore">Learn more ></span>
      </div>
      <div>
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div>
        <span class="module-title down">Business Areas</span>
      </div>
    </div>
    <div class="modul_dec description">
      <!-- 公司业务float在左边 -->
      <div class="com-desc">
        <span class="module-title">新闻动态</span>
        <span class="learnmore">Learn more ></span>
      </div>
      <div class="com-desc">
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div class="com-desc">
        <span class="module-title down">News</span>
      </div>
      <div class="com-desc com-content">
        
        
      </div>
    </div>
    <div class="modul_dec">
      <!-- 公司业务float在左边 -->
      <div>
        <span class="module-title">企业资质</span>
        <span class="learnmore">Enterprise Qualification ></span>
      </div>
      <div>
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div>
        <span class="module-title down">Business Areas</span>
      </div>
    </div>
    <div class="modul_dec partenership">
      <!-- 公司业务float在左边 -->
      <div>
        <span class="module-title">合作伙伴</span>
        <span class="learnmore">Business Partner ></span>
      </div>
      <div>
        <img style="width: 100%" src="@/assets/companys/grayline.png" alt="" />
      </div>
      <div>
        <span class="module-title down">Business Areas</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import eventBus from "../../../js/eventBus";
import { getNewsNoticeAll, getCommonInfo } from "../../../js/api/index";
export default {
  data() {
    return {
      BASE_URL: BASE_URL,
      num: 0,
      bannerItems: [],
      timer: null,
      timer2: null,
      box: "",
      newsType: 1, //新闻类型选择
      importNewslist: [],
      noticeNewsList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 6,
        type: 1,
      },
    };
  },
  computed: {
    lineStyle() {
      return {
        transition: "all ease 0.4s",
      };
    },
  },
  methods: {
    animateBanner() {
      let bannerList = this.$refs.bannerList;
      let firstItem = bannerList.children[0];
      let marginLeft = -100;
      let transitionDuration = 600;

      let Choices_line = document.getElementsByClassName("Choices_line");
      if (this.num == 0) {
        Choices_line[1].style.width = "100%";
        Choices_line[0].style.width = "0%";
      } else if (this.num == 100) {
        Choices_line[1].style.width = "0%";
        Choices_line[2].style.width = "100%";
      } else if (this.num == 200) {
        Choices_line[2].style.width = "0%";
        Choices_line[0].style.width = "100%";
      }

      bannerList.style.transition = `margin-left ${transitionDuration}ms ease`;
      bannerList.style.marginLeft = `${marginLeft}%`;

      setTimeout(() => {
        bannerList.appendChild(firstItem);
        bannerList.style.transition = "none";
        bannerList.style.marginLeft = "0";
        this.num += 100;

        if (this.num > 200) {
          this.num = 0;
        }
        firstItem = bannerList.children[0]; // 重新计算firstItem
      }, transitionDuration);
    },

    //鼠标悬停
    mouseover() {
      clearInterval(this.timer);
      this.timer = null;
    },

    changeNewsType(type) {
      this.newsType = type;
      this.pageInfo.type = type;
      this.initNewsData();
    },
    splitTime(time) {
      // 分割日期和时间
      var splitDateTime = time.split(" ");
      var date = splitDateTime[0];
      var time = splitDateTime[1];
      // 分割年、月、日
      var splitDate = date.split("-");
      var year = splitDate[0];
      var month = splitDate[1];
      var day = splitDate[2];
      return {
        year: year,
        monday: month + "." + day,
      };
    },
    //初始化信息
    initNewsData() {
      let that = this;
      getNewsNoticeAll(this.pageInfo).then((res) => {
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = that.splitTime(element.createTime);
        }
        this.importNewslist = list;
      });
    },
    //通知公告滚动条
    initNoticeData() {
      let data = {
        pageNum: 1,
        pageSize: 6,
        type: 2,
      };
      getNewsNoticeAll(data).then((res) => {
        let list = res.data.list;
        this.noticeNewsList = list;
      });
    },
    //路由跳转新闻详情页
    goToDetail(id) {
      eventBus.emit("currentChange", 3);
      this.$router.push({ name: "newsdetail", params: { id } }); // 只能用 name
    },
    //初始化轮播图标
    initBannerItem() {
      getCommonInfo().then((res) => {
        let list = res.data.list[0];
        let imgList = list.topPictures.split(",");
        this.bannerItems = imgList;
      });
    },
    //跳转新页面
    handleClick() {
      window.open("http://58.223.177.10:13000/portal", "_blank");
    },
  },
  created() {
    this.initNewsData();
    this.initNoticeData();
    this.initBannerItem();
  },
  mounted() {
    this.timer2 = setInterval(() => {
      this.animateBanner();
    }, 3000);
  },
  //销毁定时器
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.timer = null;
    this.timer2 = null;
  },
};
</script>
  
  <style lang="scss" scoped>
.top-banner {
  position: relative;
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  .banner-num {
    width: 33.33%;
    text-align: left;
    position: relative;
    .no-num {
      font-size: 1vw;
      margin-bottom: 10px;
    }
  }
  .Choices_banner {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .Choices_banner ul {
    width: 400%;
    overflow: hidden;
    list-style: none;
    transition: margin-left 0.6s ease;
  }
  .Choices_banner li {
    float: left;
    width: 25%;
  }
  .Choices_line {
    height: 3px;
    background: #146bcd;
    width: 0;
    position: absolute;
    transition: all ease 0.4s;
  }
  .choice-part {
    position: absolute;
    bottom: 10%;
    width: 80vw;
    left: 10vw;
    display: flex;
  }
}

.module-title {
  line-height: 45px;
  display: inline-block;
  color: rgb(26, 70, 146);
  font-size: 32px;
  font-weight: bold;
  float: left;
}
.down {
  color: #b0b0b0;
  font-weight: normal;
}
.learnmore {
  line-height: 45px;
  display: inline-block;
  color: #b0b0b0;
  font-size: 32px;
  font-weight: bold;
  float: right;
}
.modul_dec {
  // text-align: center;
  margin-left: 12.5vw;
  width: 75vw;
  // background-color:red;
  height: 60vh;
  margin-top: 1vh;
}
// 阴影和圆角
// .module-title{
//   box-shadow: 0 0 0 1px #e6e6e6 inset, 0 0 0 2px #fff;
//   border-radius: 4px;
// }
.description {
  width: 86vw;
  margin-left: 7vw;
  box-shadow: 7px -6px 5px 1px #e6e6e6, inset 10px 4px 6px 5px #fff;
  border-radius: 10px;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10vh;
  // background: url('../../../assets/companys/profilebag.jpg') no-repeat center center;
  // background-size: 100% 100%;
  // background-color: #ebe8e8;
}
.com-desc {
  width: 75vw;
  margin-left: 5.5vw;
}
.com-content {
  height: 40vh;
  // width: 75vw;
  margin-top: 5vh;
  text-align: center;
}
.contentdiv {
  vertical-align: middle;
  //行内平铺
  display: inline-block;
  // display: inline-flex;
  width: 30vw;
  height: 40vh;
  // background: #b0b0b0;
  // margin-left: vw;
}
.companytitle{
  display: block;
  // background: #ad5f5f;
  height: 5vh;
  margin-top: 8vh;
  font-size: 26px;
  font-weight: bolder;
  text-align: left;
}
.companydec{
  display: block;
  // background: #49c782;
   height: 20vh;
   font-size: 20px;
  text-align: left;
  margin-top: 2vh;
  line-height: 2.2em;
}
.companyimg img{
  // display: none;
  display: block;
  margin: 0 auto;
  height: 30vh;
  width: 24vw;
   border-radius: 0 180px 0 180px;
  // background: #fff;
  // margin-left: 3vw;
   margin-top: 5vh;
}
.com-content-right {
  // background: #146bcd;
}
</style>